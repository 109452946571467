const mock = [
  {
    value: 'CADASTROS',
    label: 'Cadastros',
    permissions: [
      {
        value: 'block_entidades',
        label: 'Bloquear acesso às entidades',
      },
      {
        value: 'block_produtos',
        label: 'Bloquear acesso aos produtos',
      },
      {
        value: 'block_tabelas',
        label: 'Bloquear acesso às tabelas',
      },
      {
        value: 'block_regras',
        label: 'Bloquear acesso às regras',
      },
      {
        value: 'block_regras_preco',
        label: 'Bloquear acesso às regras de preço',
      },
      {
        value: 'block_regras_cfop',
        label: 'Bloquear acesso às regras de CFOP',
      },
      {
        value: 'block_regras_tributacao',
        label: 'Bloquear acesso às regras de tributação',
      },
      {
        value: 'block_regras_integracao_contabil',
        label: 'Bloquear acesso às regras de integração contábil',
      },
      {
        value: 'block_integracoes',
        label: 'Bloquear acesso às integrações',
      },
      {
        value: 'block_integracoes_bling',
        label: 'Bloquear acesso às integrações da Bling',
      },
      {
        value: 'block_integracoes_mercadolivre',
        label: 'Bloquear acesso às integrações do Mercado Livre',
      },
      {
        value: 'block_integracoes_shopee',
        label: 'Bloquear acesso às integrações da Shopee',
      },
      {
        value: 'block_altera_produto',
        label: 'Bloquear alteração de produtos',
      },
      {
        value: 'block_analise_produto',
        label: 'Bloquear análise de produtos',
      },
      {
        value: 'block_detalhe_produto',
        label: 'Bloquear detalhe de produtos',
      },
      {
        value: 'block_exclui_produto',
        label: 'Bloquear exclusão de produtos',
      },
      {
        value: 'block_inclui_produto',
        label: 'Bloquear inclusão de produtos',
      },
      {
        value: 'block_duplica_produto',
        label: 'Bloquear duplicação de produtos',
      },
      {
        value: 'block_etiqueta_produto',
        label: 'Bloquear impressão de etiquetas de produtos',
      },
      {
        value: 'block_ativa_produto',
        label: 'Bloquear ativação de produtos',
      },
      {
        value: 'block_inativa_produto',
        label: 'Bloquear inativação de produtos',
      },
      {
        value: 'block_ver_precos_produto',
        label: 'Bloquear acesso aos preços do produto',
      },
      {
        value: 'block_permissoes_usuario',
        label: 'Bloquear permissões de usuário',
      },
      {
        value: 'block_tabela_situacao_tributaria',
        label: 'Bloquear acesso a tela de Situação Tributária',
      },
    ],
  },
  {
    value: 'COMERCIAL',
    label: 'Comercial',
    permissions: [
      {
        value: 'block_frente_caixa',
        label: 'Bloquear acesso ao frente de caixa',
      },
      {
        value: 'block_orcamentos',
        label: 'Bloquear acesso aos orçamentos',
      },
      {
        value: 'block_pedidos',
        label: 'Bloquear acesso aos pedidos',
      },
      {
        value: 'block_notas',
        label: 'Bloquear acesso às notas',
      },
      {
        value: 'block_contratos',
        label: 'Bloquear acesso aos contratos',
      },
      {
        value: 'block_entregas',
        label: 'Bloquear acesso às entregas',
      },
      {
        value: 'block_remessas',
        label: 'Bloquear acesso às remessas',
      },
      {
        value: 'block_remessas_venda_externa',
        label: 'Bloquear acesso às remessas para venda externa',
      },
      {
        value: 'block_remessas_industrializacao',
        label: 'Bloquear acesso às remessas para industrialização',
      },
      {
        value: 'block_remessas_demonstracao',
        label: 'Bloquear acesso às remessas para demonstração',
      },
      {
        value: 'block_remessas_exposicaofeira',
        label: 'Bloquear acesso às remessas para exposição / feira',
      },
      {
        value: 'block_remessas_consertoreparo',
        label: 'Bloquear acesso às remessas para conserto / reparo',
      },
      {
        value: 'block_remessas_comodato',
        label: 'Bloquear acesso às remessas para comodato',
      },
      {
        value: 'block_remessas_garantia',
        label: 'Bloquear acesso às remessas para garantia',
      },
      {
        value: 'block_remessas_troca',
        label: 'Bloquear acesso às remessas para troca',
      },
      {
        value: 'block_garantias',
        label: 'Bloquear acesso às garantias',
      },
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
      {
        value: 'block_cancela_documentos',
        label: 'Bloquear cancelamento de documentos',
      },
      {
        value: 'block_exclui_documentos',
        label: 'Bloquear exclusão de documentos',
      },
      {
        value: 'block_altera_precos',
        label: 'Bloquear alteração de preços',
      },
      {
        value: 'block_dashboard_orcamentos',
        label: 'Bloquear gráfico de orçamentos do dashboard',
      },
      {
        value: 'block_dashboard_pedidos',
        label: 'Bloquear gráfico de pedidos do dashboard',
      },
      {
        value: 'block_dashboard_faturas',
        label: 'Bloquear gráfico de faturas do dashboard',
      },
      {
        value: 'block_dashboard_faturamentos',
        label: 'Bloquear gráfico de faturamentos por vendedor do dashboard',
      },
      {
        value: 'block_altera_vendedor',
        label: 'Bloquear alteração de vendedores de vendas concluidas',
      },
      {
        value: 'enable_pedido_compra',
        label: 'Permitir gerar pedido de compras',
      },
      {
        value: 'block_altera_pedido_com_orcamento',
        label: 'Bloquear alteração de pedidos originados por um orçamento',
      },
    ],
  },
  {
    value: 'CONTABIL',
    label: 'Contábil',
    permissions: [
      {
        value: 'block_lancamentos',
        label: 'Bloquear acesso aos lançamentos',
      },
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
    ],
  },
  {
    value: 'FINANCEIRO',
    label: 'Financeiro',
    permissions: [
      {
        value: 'block_dashboard_inadimplencias',
        label: 'Bloquear gráfico de inadimplências do dashboard',
      },
      {
        value: 'block_dashboard_inadimplencias_vendedor',
        label: 'Bloquear gráfico de inadimplências por vendedor do dashboard',
      },
      {
        value: 'block_despesas',
        label: 'Bloquear acesso às despesas',
      },
      {
        value: 'block_previsoes',
        label: 'Bloquear acesso às previsões',
      },
      {
        value: 'block_caixabancos',
        label: 'Bloquear acesso ao caixa e bancos',
      },
      {
        value: 'block_caixabancos_contas',
        label: 'Bloquear acesso às contas de caixa e bancos',
      },
      {
        value: 'block_caixabancos_lancamentos',
        label: 'Bloquear acesso aos lançamentos das contas de caixa e bancos',
      },
      {
        value: 'block_caixabancos_lancamentos_detalhados',
        label:
          'Bloquear acesso aos detalhes dos lançamentos das contas de caixa e bancos',
      },
      {
        value: 'block_pendencias',
        label: 'Bloquear acesso às pendências',
      },
      {
        value: 'block_pendencias_recebimentos',
        label: 'Bloquear acesso aos recebimentos pendentes',
      },
      {
        value: 'block_pendencias_pagamentos',
        label: 'Bloquear acesso aos pagamentos pendentes',
      },
      {
        value: 'block_pendencias_cheques',
        label: 'Bloquear acesso aos cheques pendentes',
      },
      {
        value: 'block_pendencias_haveres',
        label: 'Bloquear acesso aos haveres pendentes',
      },
      {
        value: 'block_baixas',
        label: 'Bloquear acesso às baixas',
      },
      {
        value: 'block_baixas_recebimentos',
        label: 'Bloquear acesso aos recebimentos baixados',
      },
      {
        value: 'block_baixas_pagamentos',
        label: 'Bloquear acesso aos pagamentos baixados',
      },
      {
        value: 'block_baixas_cheques',
        label: 'Bloquear acesso aos cheques baixados',
      },
      {
        value: 'block_baixas_haveres',
        label: 'Bloquear acesso aos haveres baixados',
      },
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
    ],
  },
  {
    value: 'FISCAL',
    label: 'Fiscal',
    permissions: [
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
      {
        value: 'block_apuracao',
        label: 'Bloquear acesso à apuração',
      },
    ],
  },
  {
    value: 'PRODUCAO',
    label: 'Produção',
    permissions: [
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
      {
        value: 'block_apontamento_producao',
        label: 'Bloquear opção para apontar horas em uma ordem de produção',
      },
    ],
  },
  {
    value: 'SERVICOS',
    label: 'Serviços',
    permissions: [
      {
        value: 'block_orcamentos',
        label: 'Bloquear acesso aos orçamentos',
      },
      {
        value: 'block_servicos',
        label: 'Bloquear acesso aos serviços',
      },
      {
        value: 'block_equipamentos',
        label: 'Bloquear acesso aos equipamentos',
      },
      {
        value: 'block_agenda',
        label: 'Bloquear acesso à agenda',
      },
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
      {
        value: 'block_dashboard_pendentes',
        label: 'Bloquear gráfico de serviços pendentes do dashboard',
      },
      {
        value: 'block_dashboard_pendentes_status',
        label: 'Bloquear gráfico de serviços pendentes por status do dashboard',
      },
      {
        value: 'block_dashboard_pendentes_tecnico',
        label:
          'Bloquear gráfico de serviços pendentes por técnico do dashboard',
      },
      {
        value: 'block_criar_orcamento',
        label: 'Bloquear opção para criar um orçamento',
      },
      {
        value: 'block_encaminhar_orcamento',
        label: 'Bloquear opção para encaminhar um orçamento',
      },
      {
        value: 'block_reprova_orcamento',
        label: 'Bloquear opção para reprovar um orçamento',
      },
      {
        value: 'block_descarte_equipamento',
        label: 'Bloquear opção para descartar um equipamento',
      },
      {
        value: 'block_entrega_equipamento',
        label: 'Bloquear opção para entregar um equipamento',
      },
      {
        value: 'block_devolucao_equipamento',
        label: 'Bloquear opção para devolver um equipamento',
      },
      {
        value: 'block_gerar_servico',
        label: 'Bloquear opção para gerar um serviço',
      },
      {
        value: 'block_agendamento_servico',
        label: 'Bloquear opção para agendar um serviço',
      },
      {
        value: 'block_direcionamento_tecnico',
        label: 'Bloquear opção para direcionar um serviço para um técnico',
      },
      {
        value: 'block_cancelamento_servico',
        label: 'Bloquear opção para cancelar um serviço',
      },
      {
        value: 'block_conclusao_servico',
        label: 'Bloquear opção para concluir um serviço',
      },
      {
        value: 'block_faturamento_servico',
        label: 'Bloquear opção para faturar um serviço',
      },
      {
        value: 'block_resumo_servico',
        label: 'Bloquear opção para ver o resumo um serviço',
      },
      {
        value: 'block_apontamento_servico',
        label: 'Bloquear opção para apontar horas em um serviço',
      },
      {
        value: 'block_gera_garantia',
        label: 'Bloquear opção para gerar garantia',
      },
      {
        value: 'block_gera_req_compra',
        label: 'Bloquear opção para gerar requisição de compra',
      },
    ],
  },
  {
    value: 'SUPRIMENTOS',
    label: 'Suprimentos',
    permissions: [
      {
        value: 'block_estoque',
        label: 'Bloquear acesso ao estoque',
      },
      {
        value: 'block_estoque_inventario',
        label: 'Bloquear acesso ao inventário de estoque',
      },
      {
        value: 'block_estoque_transferencia',
        label: 'Bloquear acesso a transferência de estoque',
      },
      {
        value: 'block_estoque_movimentacao',
        label: 'Bloquear acesso a movimentação de estoque',
      },
      {
        value: 'block_compras',
        label: 'Bloquear acesso às compras',
      },
      {
        value: 'block_compras_notas',
        label: 'Bloquear acesso às notas de compra',
      },
      {
        value: 'block_compras_pedidos',
        label: 'Bloquear acesso aos pedidos de compra',
      },
      {
        value: 'block_compras_requisicoes',
        label: 'Bloquear acesso às requisições de compra',
      },
      {
        value: 'block_compras_requisicoes_grid',
        label:
          'Bloquear acesso às requisições de compra de outros requisitantes',
      },
      {
        value: 'block_compras_requisicoes_excluir',
        label:
          'Bloquear exclusão de requisições de compra de outros requisitantes',
      },
      {
        value: 'block_compras_requisicoes_adicionar',
        label: 'Bloquear geração de requisições de compra',
      },
      {
        value: 'block_compras_requisicoes_cotar',
        label:
          'Bloquear geração de cotações a partir de uma requisições de compra',
      },
      {
        value: 'block_compras_requisicoes_gerar_pedido',
        label:
          'Bloquear geração de pedidos a partir de uma requisições de compra',
      },
      {
        value: 'block_compras_requisicoes_necessidade',
        label: 'Bloquear cálculo de necessidade de suprimentos',
      },
      {
        value: 'block_manifestacoes',
        label: 'Bloquear acesso às manifestações do destinatário',
      },
      {
        value: 'block_retornaveis',
        label: 'Bloquear acesso aos retornáveis',
      },
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
      {
        value: 'block_dashboard_requisicoes',
        label: 'Bloquear gráfico de requisições de compra do dashboard',
      },
      {
        value: 'block_dashboard_pedidos',
        label: 'Bloquear gráfico de pedidos do dashboard',
      },
      {
        value: 'block_dashboard_faturas',
        label: 'Bloquear gráfico de faturas do dashboard',
      },
      {
        value: 'block_dashboard_expedicoes',
        label: 'Bloquear gráfico de expedições',
      },
    ],
  },
  {
    value: 'WMS',
    label: 'WMS',
    permissions: [
      {
        value: 'block_armazem',
        label: 'Bloquear acesso aos armazéns',
      },
      {
        value: 'block_estoque',
        label: 'Bloquear acesso à gestão de estoque',
      },
      {
        value: 'block_expedicoes',
        label: 'Bloquear acesso às expedições',
      },
      {
        value: 'block_relatorios',
        label: 'Bloquear acesso aos relatórios',
      },
    ],
  },
];

export default mock;
